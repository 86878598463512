import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BlogService } from '../blog.service';
import { finalize } from 'rxjs/operators';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { InfiniteScroll } from '@common/core/ui/infinite-scroll/infinite.scroll';
import { ActivatedRoute, Router } from '@angular/router';
var BlogCategoryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BlogCategoryComponent, _super);
    function BlogCategoryComponent(blog, zone, urls, route, router) {
        var _this = _super.call(this) || this;
        _this.blog = blog;
        _this.zone = zone;
        _this.urls = urls;
        _this.route = route;
        _this.router = router;
        _this.loading$ = new BehaviorSubject(false);
        _this.articles$ = new BehaviorSubject(null);
        return _this;
        //let blogcat =route.snapshot.url[1].path; // array of states
    }
    BlogCategoryComponent.prototype.ngOnInit = function () {
        this.loadArticles();
        _super.prototype.ngOnInit.call(this);
    };
    BlogCategoryComponent.prototype.loadArticles = function () {
        var _this = this;
        this.loading$.next(true);
        var page = this.articles$.value ? (this.articles$.value.current_page + 1) : 1;
        var cat = this.route.snapshot.url[1].path;
        this.blog.getAllCat({ perPage: 10, page: page, stripHtml: true, category: cat })
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            if (_this.articles$.value) {
                response.pagination.data = _this.articles$.value.data.concat(response.pagination.data);
            }
            _this.articles$.next(response.pagination);
        });
    };
    BlogCategoryComponent.prototype.loadMoreItems = function () {
        this.loadArticles();
    };
    BlogCategoryComponent.prototype.canLoadMore = function () {
        return this.articles$.value && (this.articles$.value.current_page < this.articles$.value.last_page);
    };
    BlogCategoryComponent.prototype.isLoading = function () {
        return this.loading$.value;
    };
    return BlogCategoryComponent;
}(InfiniteScroll));
export { BlogCategoryComponent };
