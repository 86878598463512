import { MEDIA_TYPE } from '../media-type';
import { slugifyString } from '@common/core/utils/slugify-string';
import * as i0 from "@angular/core";
var TitleUrlsService = /** @class */ (function () {
    function TitleUrlsService() {
    }
    TitleUrlsService.prototype.mediaItem = function (item) {
        switch (item.type) {
            case MEDIA_TYPE.TITLE:
                var i = item;
                return ['/titles', item.id, i.name ? slugifyString(i.name) : ''];
            case MEDIA_TYPE.PERSON:
                return ['/people', item.id, slugifyString(item.name)];
            case MEDIA_TYPE.EPISODE:
                var ep = item;
                return ['/titles', ep.title_id, 'season', ep.season_number, 'episode', ep.episode_number];
            case MEDIA_TYPE.NEWS:
                return ['/news', item.id, slugifyString(item.title)];
            case MEDIA_TYPE.BLOG:
                return ['/', slugifyString(item.title) + "-blog", item.id];
        }
    };
    TitleUrlsService.prototype.season = function (series, seasonNumber) {
        return ['/titles', series.id, slugifyString(series.name), 'season', seasonNumber];
    };
    TitleUrlsService.prototype.episode = function (series, season, episode) {
        return this.season(series, season).concat(['episode', episode]);
    };
    TitleUrlsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleUrlsService_Factory() { return new TitleUrlsService(); }, token: TitleUrlsService, providedIn: "root" });
    return TitleUrlsService;
}());
export { TitleUrlsService };
