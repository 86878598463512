import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var BlogService = /** @class */ (function () {
    function BlogService(http) {
        this.http = http;
    }
    BlogService.prototype.getAll = function (params) {
        return this.http.get('blog', params);
    };
    BlogService.prototype.getAllCat = function (params) {
        return this.http.get('blog', params);
    };
    BlogService.prototype.get = function (id) {
        return this.http.get('blog/' + id);
    };
    BlogService.prototype.create = function (payload) {
        return this.http.post('blog', payload);
    };
    BlogService.prototype.update = function (id, payload) {
        return this.http.put('blog/' + id, payload);
    };
    BlogService.prototype.delete = function (params) {
        return this.http.delete('blog', params);
    };
    BlogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlogService_Factory() { return new BlogService(i0.ɵɵinject(i1.AppHttpClient)); }, token: BlogService, providedIn: "root" });
    return BlogService;
}());
export { BlogService };
